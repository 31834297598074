.Wrapper {
  position: relative;
  height: 8rem;
  width: 100%;
  -webkit-box-shadow: -4px 4px 6px 0px rgba(24, 31, 20, 0.1);
  box-shadow: -4px 4px 6px 0px rgba(24, 31, 20, 0.1);
  overflow: hidden;
}
.Wrapper .Image {
  height: 100%;
  width: 100%;
  background-color: #e3e3e3;
}
.Wrapper .TextWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(80% - 1rem);
  padding: 1rem;
  border: 3px solid #7d8c73;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.2s ease-out;
}
.Wrapper .TextWrapper .Text {
  padding: 0.1rem;
  border-radius: 2px;
  height: 1rem;
  background-color: #bfbfbf;
}