.Fieldset legend {
  grid-column: 1/-1;
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.Fieldset .Main {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .Fieldset .Main {
    grid-template-columns: 1fr;
  }
}