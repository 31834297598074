.Header {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.Header h1 {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0 0 1rem 0;
}
.Header .Instagram {
  position: absolute;
  right: 2rem;
  transform: translateY(-3rem);
  font-size: 2rem;
}
.Header .Categoria,
.Header .Cidade {
  display: block;
  margin-bottom: 1rem;
  color: #666666;
  font-weight: 600;
}
.Header .Legenda {
  font-size: 0.9rem;
}
.Header .Legenda .Dias {
  display: inline-block;
  padding: 0;
  margin: 0.5rem 2rem 0 0;
  list-style: none;
}
.Header .Legenda .Dias li {
  display: inline;
  margin-right: 0.8rem;
  font-weight: 700;
  color: var(--red);
  text-decoration: line-through;
}
.Header .Legenda .Dias li:last-child {
  margin-right: 0;
}
.Header .Legenda .Dias .Ativo {
  color: var(--secondary-color);
  text-decoration: none;
  word-spacing: 0.5rem;
}
.Header .Legenda .Dias .CalendarIcon {
  color: inherit;
}

@media (max-width: 768px) {
  .Header {
    padding: 1rem 0.8rem;
    font-size: 0.8rem;
  }
  .Header h1 {
    font-size: 1.5rem;
  }
  .Header .Instagram {
    right: 0.5rem;
  }
  .Header .Categoria {
    margin-bottom: 1.5rem;
  }
  .Header .Legenda {
    display: flex;
    flex-direction: column;
  }
  .Header .Legenda .Dias {
    padding: 0;
    margin: 0.5rem 0;
  }
  .Header .Legenda .Dias .CalendarIcon {
    display: none;
  }
}