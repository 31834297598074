.ImageWrapper {
  position: relative;
  height: 150px;
  width: 255px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 240px 114px !important;
  background-color: lightgray;
  border-radius: 0.5rem;
  opacity: 0.8;
}
.ImageWrapper:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 0.3rem;
  z-index: -1;
}

.Title {
  width: 90%;
  height: 0.8rem;
  margin: 0.2rem 0;
  background-color: lightgray;
}

.LegendWrapper {
  display: flex;
  gap: 0.6rem;
  font-size: smaller;
  color: #595959;
}
.LegendWrapper .Legends {
  width: 70%;
  display: flex;
  gap: 0.3rem;
}
.LegendWrapper .Legends .Legend {
  width: 60%;
  height: 0.6rem;
  background-color: lightgray;
}

@media (max-width: 768px) {
  .ImageWrapper {
    width: 100%;
  }
}