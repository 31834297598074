.Title {
  display: block;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 2rem 0 0;
}
.Wrapper .NoResult {
  fill: 1;
  width: 100%;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
}
.Wrapper .ServicosWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  width: 100%;
}

@media (min-width: 769px) {
  .Title {
    margin: 1rem 2rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .Title {
    margin: 1rem;
  }

  .Wrapper {
    flex-direction: column;
    padding: 0 1rem;
  }
  .Wrapper .ServicosWrapper {
    grid-template-columns: 1fr;
  }
  .Wrapper .Categorias {
    font-size: 1.1rem;
  }
  .Wrapper .Categorias li a {
    display: block;
    padding: 0.3rem 0;
  }
}