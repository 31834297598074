.Wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}
.Wrapper .Selector {
  width: 25%;
  height: 100%;
  overflow: hidden auto;
}
.Wrapper .Selector img {
  width: 95%;
  height: 100px;
  object-fit: cover;
  border: 1px solid var(--secondary-color);
  border-radius: 0.3rem;
  cursor: pointer;
}
.Wrapper .SelectedImg {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  padding: 1rem 0;
}
.Wrapper .SelectedImg .MainImage {
  max-width: calc(100% - 2rem);
  max-height: 100%;
}

@media (max-width: 768px) {
  .Wrapper {
    flex-direction: column;
  }
  .Wrapper .Selector {
    display: flex;
    flex-direction: row;
    order: 2;
    gap: 0.1rem;
    width: 100%;
    height: 70px;
    overflow: auto;
  }
  .Wrapper .Selector img {
    max-width: 39.5%;
    min-width: 39.5%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
  }
  .Wrapper .SelectedImg {
    align-items: center;
    width: 100%;
    max-height: calc(100% - 1.5rem - 70px);
    padding: 0.8rem 0;
  }
  .Wrapper .SelectedImg .MainImage {
    max-width: 100%;
    max-height: calc(100% - 1rem);
    width: auto;
    height: auto;
  }
}