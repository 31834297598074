.FilePreview {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--light-tertiary-color);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: grab;
}
.FilePreview:active {
  cursor: grabbing;
}
.FilePreview .ImageWrapper {
  background-color: rgba(49, 75, 32, 0.3);
  height: 5rem;
  width: 10rem;
  overflow: hidden;
  border: 0.1rem solid;
  border-radius: 0.3rem;
  text-align: center;
}
.FilePreview .ImageWrapper .Image {
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
@media (max-width: 768px) {
  .FilePreview .ImageWrapper {
    height: 4rem;
    width: 8rem;
  }
}
.FilePreview .DeleteButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--red);
  font-size: 1.1rem;
}

.InvalidFile {
  background-color: rgba(255, 92, 92, 0.1);
}
.InvalidFile .ImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--red);
}
.InvalidFile .ImageWrapper .WarningIcon {
  font-size: 2rem;
  color: #fff;
}
.InvalidFile .InvalidLegend {
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--red);
  font-size: 0.7rem;
  font-weight: 700;
}

.Dragging {
  opacity: 50%;
}