.PermissionCard {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2000;
  background-color: #fff;
  padding: var(--card-padding);
  -webkit-box-shadow: 0 0 5px 0 #000;
  box-shadow: 0 0 5px 0 #000;
  animation: emerge 0.8s ease-in-out forwards;
}
.PermissionCard .ButtonsWrapper {
  display: flex;
  width: calc(100% - calc(2 * var(--card-padding)));
  justify-content: space-between;
  bottom: 0.5rem;
}

@media (min-width: 769px) {
  .PermissionCard {
    --card-padding: 0.8rem;
    width: calc(400px - var(--card-padding));
    height: calc(170px - var(--card-padding));
    bottom: 0;
    left: 0;
    border-top-right-radius: 18px;
    transform: translateY(100%);
  }
  .PermissionCard .ButtonsWrapper {
    position: absolute;
    display: flex;
    width: calc(100% - calc(2 * var(--card-padding)));
    bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
  .PermissionCard {
    --card-padding: 0.3rem;
    position: fixed;
    bottom: 0;
    height: auto;
  }
  .PermissionCard .ButtonsWrapper {
    display: flex;
    width: calc(100% - calc(2 * var(--card-padding)));
    bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
@keyframes emerge {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}