.Wrapper .Categorias ul {
  list-style: none;
  padding: 0 0.1rem;
  margin: 0;
}

@media (min-width: 769px) {
  .Button,
.CloseButton {
    display: none;
  }

  .Wrapper {
    display: block !important;
    width: 17vw;
    height: 60vh;
    margin: 0 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    font-size: small;
  }
  .Wrapper .Categorias {
    margin: 1rem 0;
  }
}
@media (max-width: 768px) {
  .Button {
    width: calc(100% - 0.6rem);
    padding: 0.5rem 0;
    margin: 0 0.3rem;
    border: 1px solid #cccccc;
    text-align: center;
  }

  .Wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    left: 0;
    z-index: 1;
    background-color: #fff;
    transform: translateY(100%);
    animation: emerge 0.4s ease-out forwards;
  }
  .Wrapper .MinhaRegiaoButton {
    padding: 1rem;
    padding-bottom: 0;
  }
  .Wrapper .Categorias {
    margin: 2rem 0;
    padding: 1rem;
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
  }
  .Wrapper .Categorias strong {
    font-size: 1.1rem;
  }
  .Wrapper .Categorias ul {
    margin: 0.5rem 0;
  }
  .Wrapper .Categorias ul li {
    margin: 1rem 0;
  }
  .Wrapper .Categorias ul li .Link {
    text-decoration: none;
    font-weight: 600;
  }
  .Wrapper .CloseButton {
    position: absolute;
    right: 1rem;
  }
}
@keyframes emerge {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(3rem);
  }
}