@charset "UTF-8";
.Fieldset legend {
  grid-column: 1/-1;
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.Fieldset .DisponibilidadeWrapper {
  display: flex;
  justify-content: space-between;
}
.Fieldset .DisponibilidadeWrapper .DaysWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.Caracteristicas {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 0.5rem 1rem;
}
.Caracteristicas ul {
  max-height: 15rem;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0.5rem 1rem;
}

.Servicos {
  max-height: 15rem;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 0.5rem 1rem 0;
}
.Servicos ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem 1rem;
  min-height: 1rem;
}
.Servicos ul .ServicosDeleteButton {
  background-color: #fff;
  border: 0;
  color: var(--primary-color);
  cursor: pointer;
}
.Servicos ul .ServicosDeleteButton:after {
  content: "✓";
  font-weight: bold;
  font-size: 1.1rem;
}
.Servicos ul .ServicosDeleteButton:hover {
  color: var(--red);
}
.Servicos ul .ServicosDeleteButton:hover:after {
  display: inline-block;
  content: "X";
  padding-top: 0.2rem;
}

@media (min-width: 769px) {
  .Caracteristicas,
.Servicos {
    width: calc(50% - 2.5rem);
  }

  .Group {
    display: flex;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .Caracteristicas,
.Servicos {
    margin: 1rem 0;
  }
  .Caracteristicas li,
.Servicos li {
    margin: 1rem 0;
  }

  .Fieldset .DisponibilidadeWrapper {
    flex-direction: column;
  }
  .Fieldset .DisponibilidadeWrapper .DaysWrapper {
    flex-direction: column;
  }
}