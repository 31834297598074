.Footer {
  padding-bottom: 1.5rem;
  background-color: #fff;
}
.Footer .QueroVenderBanner {
  width: 100%;
}
.Footer .Wrapper {
  display: flex;
  height: 55%;
  padding: 0 1rem;
  background-color: #fff;
}
.Footer .Wrapper .LogoWrapper {
  position: relative;
  width: 210px;
  height: 100%;
  margin: 0 4rem;
}
.Footer .Wrapper .LogoWrapper .Logo {
  position: absolute;
  width: 210px;
  height: 99px;
  top: 50%;
  transform: translateY(50%);
}
.Footer .Wrapper .Col {
  flex-grow: 1;
  padding: 0 1rem;
}
.Footer .Wrapper .Col .Divisor {
  margin: 1rem 0;
  border: 1px solid var(--light-tertiary-color);
  filter: opacity(20%);
}
.Footer .Wrapper .Col .Icon {
  margin-right: 0.3rem;
  color: #3399cc;
}
.Footer .Wrapper .Col .LogoSocialMedia {
  display: inline;
  margin-left: 1rem;
}
.Footer .Wrapper .Col .LogoSocialMedia img {
  width: 35px;
  margin-bottom: 0.6rem;
}
.Footer .Wrapper .Col .Small {
  margin-bottom: 0.1rem;
  color: #666666;
  font-size: 0.8rem;
}
.Footer .Wrapper .Col h1 {
  width: 100%;
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  font-weight: 450;
}
.Footer .Wrapper .Col a,
.Footer .Wrapper .Col span {
  display: block;
  margin-left: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.Footer .Wrapper .Col a {
  margin-bottom: 1rem;
  text-decoration: none;
  color: var(--primary-color);
}
.Footer .Wrapper .Col a:hover {
  color: var(--tertiary-color);
}

@media (max-width: 768px) {
  .Footer {
    position: relative;
  }
  .Footer .Wrapper {
    flex-direction: column;
    height: 550px;
    padding: 0;
  }
  .Footer .Wrapper .Logo {
    display: none;
  }
}