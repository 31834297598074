.Banner {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 80px 500px 80px rgba(0, 0, 0, 0.376);
}
.Banner .Content {
  color: #fff;
}
.Banner .Content .Title {
  margin: 0.5rem 0;
  font-size: 1.85rem;
  font-weight: 400;
}
.Banner .Content .Logo {
  width: 135px;
  height: 63px;
}
.Banner .Content .Subtitle {
  margin: 0.5rem 0;
}

@media (min-width: 769px) {
  .Banner {
    height: 250px;
    padding-bottom: 1rem;
  }
  .Banner .Content {
    padding: 3rem 8rem;
  }
}
@media (max-width: 768px) {
  .Banner .Content {
    padding: 1.5rem 2.5rem;
  }
  .Banner .Content .Subtitle {
    font-size: 0.8rem;
  }
}