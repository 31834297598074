.Wrapper {
  position: relative;
  display: flex;
  gap: 0.2rem;
  justify-content: space-between;
  justify-content: center;
  width: 100%;
  height: 298px;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
}
.Wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.2rem;
}
.Wrapper .ImageWrapper {
  width: 50%;
  height: 100%;
  background-color: lightgray;
  border-radius: 0.5rem;
}
.Wrapper .DoubleImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: inherit;
  width: 50%;
}
.Wrapper .DoubleImageWrapper .ImageWrapper {
  width: 100%;
  height: 49.5%;
  background-color: lightgray;
}
.Wrapper .PillowButton {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border: 0;
  border-radius: 0.5rem;
  padding: 0.4rem 1rem;
  color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
}
.Wrapper .PillowButton:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

@media (max-width: 768px) {
  .Wrapper {
    width: 100%;
    height: 200px;
    padding: 0;
  }
}