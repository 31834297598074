.Wrapper {
  display: flex;
  position: relative;
  align-items: center;
}
.Wrapper .Input {
  width: 100%;
  max-height: 2.4rem;
  padding: 0.8rem 2.1rem 0.8rem 2.6rem;
  font-size: 1rem;
  border: 1px solid #b0b0b0;
  border-radius: 0.5rem;
}
.Wrapper .Input:focus {
  outline: none;
}
.Wrapper .Input:focus::-webkit-input-placeholder {
  opacity: 1;
}
.Wrapper .Input:focus::-moz-input-placeholder {
  opacity: 1;
}
.Wrapper .Input::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
}
.Wrapper .Input::-moz-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
}
.Wrapper .SearchIcon {
  position: absolute;
  left: 0.8rem;
  color: gray;
}

@media (max-width: 768px) {
  .Wrapper {
    width: 100%;
  }
  .Wrapper .Input {
    padding: 0.6rem 0.8rem;
    padding-left: 2.5rem;
  }
  .Wrapper .SearchButton {
    display: none;
  }
}