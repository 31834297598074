.Wrapper {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.Wrapper:hover .Title {
  color: var(--secondary-color);
}
.Wrapper .ImageWrapper {
  position: relative;
  height: 150px;
  width: 255px;
  object-fit: cover;
  overflow: hidden;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 240px 114px !important;
}
.Wrapper .ImageWrapper:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 0.3rem;
  z-index: -1;
}
.Wrapper .ImageWrapper img {
  width: 100%;
  height: auto;
  opacity: 1;
}
.Wrapper .Title {
  font-weight: 600;
}
.Wrapper .Legend {
  font-size: smaller;
  color: #595959;
}

@media (max-width: 768px) {
  .WidthAuto .ImageWrapper {
    width: 100%;
    height: 170px;
  }
}