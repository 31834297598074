.Label {
  margin: 0 1rem;
  font-weight: 700;
  color: var(--red);
  text-decoration: line-through;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.Ativo {
  color: var(--secondary-color);
  text-decoration: none;
  word-spacing: 0.5rem;
}

@media (max-width: 768px) {
  .Day {
    margin: 0.5rem 0;
    text-align: center;
  }

  .Label:first-child, .Label:last-child {
    margin: 0;
  }
}