.Wrapper {
  display: inline-block;
  position: relative;
  height: auto;
  width: 100%;
  margin-bottom: 1.2rem;
}
.Wrapper .Label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}
.Wrapper .Textarea {
  width: calc(100% - 1.5rem);
  min-height: 1.6rem;
  max-height: calc(var(--rows-quantity) * 2rem + 0.8rem);
  padding: 0.5rem 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  resize: vertical;
  font-size: 1rem;
  font-family: inherit;
  transition: border-color 0.4s;
}
.Wrapper .Textarea::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 1.5s ease-out;
}
.Wrapper .Textarea::-moz-input-placeholder {
  opacity: 0.5;
  transition: opacity 1.5s ease-out;
}
.Wrapper .Textarea:focus {
  outline: none;
  border-color: black;
}
.Wrapper .Textarea:focus::-webkit-input-placeholder {
  opacity: 1;
}
.Wrapper .Textarea:focus::-moz-input-placeholder {
  opacity: 1;
}
.Wrapper .ErrorLegend,
.Wrapper .Legend {
  position: absolute;
  bottom: -1rem;
  right: 0;
  flex-basis: 100%;
  z-index: -1;
  font-size: 0.8rem;
  text-align: right;
}