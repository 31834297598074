.Fieldset legend {
  grid-column: 1/-1;
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.Fieldset .Group {
  display: flex;
}

@media (max-width: 768px) {
  .Fieldset .Group {
    display: block;
    margin-bottom: 3rem;
  }
}