.Wrapper {
  position: relative;
  align-self: stretch;
  width: calc(100% - 2.4rem);
  min-height: 120px;
  margin: 0;
  padding-bottom: 3.4rem;
}
.Wrapper .DropWrapper {
  display: flex;
  justify-content: center;
  border: 0.2rem dashed var(--light-tertiary-color);
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 100%;
}
.Wrapper .DropWrapper .Button {
  position: absolute;
  bottom: 1rem;
  padding: 0.4rem 0.6rem;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1rem;
}
.Wrapper .DropWrapper .Button label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.Wrapper .DropWrapper .FilePreviewList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5rem;
  list-style: none;
}
.Wrapper .DropWrapper .Instructions {
  align-self: center;
  font-weight: 600;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .DropWrapper {
    width: calc(100% - 0.9rem);
    margin: 0 0.2rem;
  }
}