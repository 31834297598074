.Switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.3rem;
}
.Switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.Switch input:checked + .Slider {
  background-color: var(--primary-color);
}
.Switch input:checked + .Slider:before {
  transform: translateX(1.5rem);
}
.Switch .Slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: 0.4s;
}
.Switch .Slider::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: 0.15rem;
  left: 0.2rem;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.5s;
}