.Header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 2rem;
  background-color: #fff;
}
.Header .LogoWrapper {
  padding: 0.5rem 0;
  text-align: center;
}
.Header .LogoWrapper .Logo {
  width: 101px;
  height: 48px;
  cursor: pointer;
}
.Header .SearchBar {
  width: 60%;
}
.Header .Navigation {
  width: 15%;
}
.Header .Navigation ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  list-style: none;
}
.Header .Navigation ul li a {
  color: inherit;
  text-decoration: none;
}
.Header .Navigation .DropButton {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 1.25rem;
  cursor: pointer;
}
.Header .Navigation .DropMenu {
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 0;
  padding: 0.6rem 0;
  border: 1px solid #f1f1f1;
  border-top: 0;
  background-color: #fff;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  font-size: 1rem;
}
.Header .Navigation .DropMenu li {
  padding: 0.3rem 1rem;
  white-space: nowrap;
  cursor: pointer;
}
.Header .Navigation .DropMenu li:hover {
  background-color: lightgray;
}
.Header .Navigation .DropMenu li .LogoutButton {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  cursor: pointer;
}

@media (min-width: 769px) {
  .HamburgerButton {
    display: none;
  }
}
@media (max-width: 768px) {
  .Header {
    position: fixed;
    padding: 0 0.4rem;
    justify-content: space-between;
    z-index: 99999;
  }
  .Header .SearchBar {
    display: none;
  }
  .Header .Navigation {
    display: none;
  }
  .Header .HamburgerButton {
    background-color: inherit !important;
    border: 0 !important;
    font-size: 2rem;
  }
}