.Wrapper {
  padding: 1rem 4rem 1rem;
}
.Wrapper .Categorias {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
  padding: 2rem 2rem 0;
}
.Wrapper .LinkText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .Wrapper {
    padding: 0;
  }
  .Wrapper .Categorias {
    grid-template-columns: 1fr;
  }
}