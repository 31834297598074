.Wrapper {
  padding: 0.5rem 2rem;
}
.Wrapper .Body section {
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0.3rem;
}
.Wrapper .Body section h2 {
  margin: 0;
  margin-bottom: 0.7rem;
  font-size: 1.2rem;
}
.Wrapper .Body .Convenios ul {
  margin: 0;
  margin-bottom: 0.2rem;
}
.Wrapper .Body .Contato svg {
  margin-right: 0.5rem;
}
.Wrapper .Body .Descricao {
  text-align: justify;
}
.Wrapper .Body .Descricao p {
  margin: 0;
}
.Wrapper .Body .Map {
  width: calc(100% - 1rem);
  min-width: 300px;
  min-height: 200px;
}

@media (max-width: 768px) {
  .Wrapper {
    padding: 0 0.8rem;
  }
  .Wrapper .Body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .Wrapper .Body .Contato,
.Wrapper .Body .Convenios,
.Wrapper .Body .Descricao,
.Wrapper .Body .Map {
    overflow-x: auto;
  }
  .Wrapper .Body .Map .MapFooter {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
@media (min-width: 769px) {
  .Wrapper .Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  .Wrapper .Body .Contato,
.Wrapper .Body .Convenios,
.Wrapper .Body .Map {
    overflow-wrap: break-word;
  }
  .Wrapper .Body .Descricao {
    grid-row: span 2;
  }
  .Wrapper .Body .Map {
    align-self: stretch;
  }
  .Wrapper .Body .Map .MapFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.4rem 0;
  }
  .Wrapper .Body .FaixaPreco {
    align-self: start;
  }
  .Wrapper .Body .Galeria {
    grid-column: span 2;
  }
}