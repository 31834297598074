.Wrapper {
  position: relative;
  height: 8rem;
  width: 100%;
  -webkit-box-shadow: -4px 4px 6px 0px rgba(24, 31, 20, 0.1);
  box-shadow: -4px 4px 6px 0px rgba(24, 31, 20, 0.1);
  cursor: pointer;
  overflow: hidden;
}
.Wrapper .Image {
  height: 100%;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  transition: all 0.2s ease-out;
}
.Wrapper .TextWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(80% - 1rem);
  padding: 1rem;
  border: 3px solid #7d8c73;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.2s ease-out;
}
.Wrapper .TextWrapper .Text {
  padding: 0.1rem;
  border-radius: 5px;
  background-color: rgba(225, 253, 206, 0.4);
  -webkit-box-shadow: 0px 0px 8px 3px rgba(225, 253, 206, 0.4);
  box-shadow: 0px 0px 8px 3px rgba(225, 253, 206, 0.4);
  color: #000;
}
.Wrapper:hover .Image {
  transform: scale(1.1);
}