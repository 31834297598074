.Wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 1.2rem 0;
}
.Wrapper .Dropdown {
  display: inline-block;
  flex-grow: 1;
  max-height: 2.6rem;
  padding: 0.6rem 1rem;
  margin: 0 1rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: border-color 0.7s ease-in-out;
}
.Wrapper .Dropdown:focus {
  outline: none;
  border-color: black;
}
.Wrapper .Dropdown .Option {
  color: var(--text-color);
  background-color: var(--background-color);
  font-size: 1rem;
}
.Wrapper .Invalid {
  border: 1px solid var(--red);
  transition: transform 0.1s;
  animation: tremble 0.1s 0.1s linear 6 alternate;
}
.Wrapper .Invalid:focus {
  border: 1px solid var(--red);
  -webkit-box-shadow: 0 0 2px 0 var(--red);
  box-shadow: 0 0 2px 0 var(--red);
}
.Wrapper .ErrorLegend {
  position: absolute;
  bottom: -1rem;
  right: 0;
  flex-basis: 100%;
  z-index: -1;
  font-size: 0.8rem;
  text-align: right;
  color: var(--red);
  transform: translateY(-1rem);
  animation: drop 0.1s ease-in-out forwards;
}
.Wrapper .Required:after {
  content: " *";
  color: var(--red);
}

.Disabled label {
  opacity: 60%;
}
.Disabled .Dropdown {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .Wrapper {
    flex-direction: column;
    align-items: stretch;
  }
  .Wrapper .Dropdown {
    margin: 0;
  }
  .Wrapper .Legend {
    font-size: 0.7rem;
    padding: 0;
  }
}
@keyframes drop {
  0% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}