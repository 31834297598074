.Fieldset legend {
  grid-column: 1/-1;
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.Fieldset .Row {
  display: flex;
}
.Fieldset .Row .Cep {
  max-width: 50%;
}
.Fieldset .Row .Numero {
  max-width: 45%;
}

.SearchCepBtn {
  align-self: center;
  padding: 0.3rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .SearchCepBtn {
    margin-top: 1.2rem;
    margin-left: 1rem;
  }
}
@media (min-width: 769px) {
  .SearchCepBtn {
    position: relative;
    right: 3rem;
  }
}

@media (max-width: 768px) {
  .Fieldset .Row {
    display: block;
  }
  .Fieldset .Row .Cep,
.Fieldset .Row .Numero {
    max-width: 100%;
  }
}