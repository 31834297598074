.Wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  margin: 1.2rem 0;
}
.Wrapper .Input {
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  flex-grow: 1;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
  transition: border-color 0.7s ease-in-out;
}
.Wrapper .Input::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
}
.Wrapper .Input::-moz-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
}
.Wrapper .Input:focus {
  outline: none;
  border-color: black;
}
.Wrapper .Input:focus::-webkit-input-placeholder {
  opacity: 1;
}
.Wrapper .Input:focus::-moz-input-placeholder {
  opacity: 1;
}
.Wrapper .Invalid {
  border: 1px solid var(--red);
  transition: transform 0.1s;
  animation: tremble 0.1s 0.1s linear 6 alternate;
}
.Wrapper .Invalid:focus {
  border: 1px solid var(--red);
  -webkit-box-shadow: 0 0 2px 0 var(--red);
  box-shadow: 0 0 2px 0 var(--red);
}
.Wrapper .Required:after {
  content: " *";
  color: var(--red);
}
.Wrapper .ErrorLegend,
.Wrapper .Legend {
  position: absolute;
  bottom: -1rem;
  right: 0;
  flex-basis: 100%;
  z-index: -1;
  font-size: 0.8rem;
  text-align: right;
}
.Wrapper .ErrorLegend {
  color: var(--red);
  transform: translateY(-1rem);
  animation: drop 0.1s ease-in-out forwards;
}

@media (max-width: 768px) {
  .Wrapper {
    flex-direction: column;
    align-items: stretch;
  }
  .Wrapper .Input {
    margin: 0;
  }
  .Wrapper .Legend {
    font-size: 0.7rem;
    padding: 0;
  }
}
@keyframes drop {
  0% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes tremble {
  0% {
    transform: translateX(-0.25rem);
  }
  100% {
    transform: translateX(0.25rem);
  }
}