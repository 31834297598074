.Background {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /*  pointer-events: none; */
  z-index: 9997;
}
@media (min-width: 768px) {
  .Background {
    display: none !important;
  }
}

.Wrapper {
  position: fixed;
  height: 100vh;
  width: 70%;
  max-width: 400px;
  z-index: 9998;
  top: 4rem;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.Wrapper ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}
.Wrapper ul li {
  padding: 0.7rem 0 0.7rem 1rem;
  border-bottom: 1px solid #464444;
}
.Wrapper ul li button,
.Wrapper ul li a {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: inherit;
  text-align: left;
  text-decoration: none;
  font-size: 1.3rem;
  color: inherit;
}
.Wrapper ul li:first-child {
  border-top: 1px solid #464444;
}
@media (min-width: 769px) {
  .Wrapper {
    display: none;
  }
}

.EnterDone {
  transform: translateX(0);
}

.Exit {
  transform: translateX(100%);
}