.Button {
  padding: 0.6rem 1.3rem;
  border: 0;
  border-radius: 0.3rem;
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: filter 0.2s ease;
}
.Button:hover {
  filter: brightness(90%);
}

.Disabled {
  background-color: gray;
  cursor: default;
  opacity: 40%;
}
.Disabled:hover {
  filter: brightness(100%);
}