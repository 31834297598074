.Modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.Modal .Content {
  position: relative;
  background-color: #fafafa;
  width: 75%;
  min-height: 30%;
  max-height: 80%;
  border-radius: 0.5rem;
  transform: translateY(-100%);
  transition: all 0.3s ease-out;
}
@media (max-width: 768px) {
  .Modal .Content {
    width: 90%;
  }
}
.Modal .Content .Header {
  border-bottom: 1px solid #000;
  min-height: 1.8rem;
  padding: 0.4rem 1.2rem;
}
.Modal .Content .Header span {
  font-size: 1.5rem;
  font-weight: 600;
}
.Modal .Content .Header .CloseButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
}
.Modal .Content .Body {
  height: 60vh;
  overflow-y: auto;
  padding: 0.5rem 1.2rem;
  padding-bottom: 1.5rem;
}
.Modal .Content .Footer {
  bottom: 0;
  padding: 0.5rem 1.2rem;
  border-top: 1px solid;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.Modal.EnterDone {
  opacity: 1;
  pointer-events: visible;
}
.Modal.EnterDone .Content {
  transform: translateY(0);
}