*::-webkit-scrollbar {
  width: 1vw;
}
*::-webkit-scrollbar-thumb {
  background-color: #999999;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #a6a6a6;
}
*::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
*::selection {
  background: #d6e7ca;
}

body {
  --primary-color: hsl(96, 93%, 21%);
  --secondary-color: hsl(96, 83%, 28%);
  --tertiary-color: hsl(96, 38%, 40%);
  --light-secondary-color: hsl(96, 83%, 32%);
  --light-tertiary-color: hsl(96, 38%, 60%);
  --red: hsl(0, 100%, 68%);
  margin: 0;
  background-color: #fafafa;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, quicksand, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}